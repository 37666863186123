'use client';

import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import useLabTestPixalEvent from '@/services/event/useLabTestPixalEvent';
import useLabAuthContext from './LabAuthContext';

import { cartStorageName } from '@/services/ecommerce/apis/config';
import {
	addTestToCart,
	deleteTestCartItem,
	getTestsCart,
	updateCartAddress,
	updateHardCopyConveyance,
	updateTestCartItem
} from '@labServices/apis/lab-test-apis/cart';
import { getSampleCollectionSchedule } from '@labServices/apis/lab-test-apis/order';
import { getLabVendors } from '@labServices/apis/lab-test-apis/vendor';
import { I_CartItem } from '@labServices/apis/types/lab-cart/type.addTestToCart';
import { IG_LT_TestCartData } from '@labServices/apis/types/lab-cart/type.getTestCartData';
import {
	IG_LT_SampleCollectionSchedule,
	IG_LT_Time
} from '@labServices/apis/types/lab-order/type.getSampleCollectionSchedule';
import { IG_LT_MostBookedTest } from '@labServices/apis/types/lab-tests/type.getMostBookedLabTests';
import { IG_LT_TestDetails } from '@labServices/apis/types/lab-tests/type.getTestOrPackageDetails';
import { IG_LT_LabVendor } from '@labServices/apis/types/lab-vendor/type.getLabVendor';
import { isEmptyArray } from '@labServices/utils/cmnHelper';
import { setItemInLocalStorage } from '@labServices/utils/session';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export interface I_LabCartContext {
	cartData?: Partial<IG_LT_TestCartData>;
	setCartData?: Dispatch<SetStateAction<IG_LT_TestCartData>>;
	loading?: boolean;
	setLoading?: Dispatch<SetStateAction<boolean>>;
	hardCopyLoading?: boolean;
	setHardCopyLoading?: Dispatch<SetStateAction<boolean>>;
	handleGetLabCartData?: () => Promise<void>;
	labTestCartAction?: (
		testId: string,
		testData: IG_LT_MostBookedTest | IG_LT_TestDetails | I_CartItem,
		patientCount: number,
		vendorId: string,
		onError: (res: any) => void
	) => Promise<void>;
	removeLabTestCartItem?: (testId: string, action?: any) => Promise<void>;
	handleUpdateLabCartLocation?: (cartUserLocationId?: string) => Promise<void>;
	additionalNote?: string;
	setAdditionalNote?: Dispatch<SetStateAction<string>>;
	labVendors?: IG_LT_LabVendor[];
	setLabVendors?: Dispatch<SetStateAction<IG_LT_LabVendor[]>>;
	handleGetSampleCollectionSchedule?: () => Promise<void>;
	dateTimeSchedules?: I_ScheduleDateTimeState;
	setDateTimeSchedules?: Dispatch<SetStateAction<I_ScheduleDateTimeState>>;
	selectedDateTime?: I_SelectedDateTimeState;
	setSelectedDateTime?: Dispatch<SetStateAction<I_SelectedDateTimeState>>;
	handleUpdateHardCopyConveyance?: (e: any) => Promise<void>;
	couponCode?: string;
	setCouponCode?: Dispatch<SetStateAction<string>>;
	dateTimeLoading?: boolean;
	setDateTimeLoading?: Dispatch<SetStateAction<boolean>>;
}

interface I_ScheduleDateTimeState {
	dates: IG_LT_SampleCollectionSchedule[];
	times: IG_LT_Time[];
}

interface I_SelectedDateTimeState {
	date: string;
	time: string;
}

export const LabCartContext = createContext<I_LabCartContext | null>(null);

export const LabCartContextProvider = ({ children }: { children: React.ReactNode }) => {
	const { setcartTotalPriceItems } = useLabAuthContext();
	const { isLogged } = useAuth();
	const { LabTestAddToCartEvent } = useLabTestPixalEvent();

	const { carts: webCart, drawerOpen, setDrawerOpen, currentlocation } = useCart();
	const [cartData, setCartData] = useState<Partial<IG_LT_TestCartData>>({});
	const [labVendors, setLabVendors] = useState<IG_LT_LabVendor[]>([]);
	const [additionalNote, setAdditionalNote] = useState<string>('');

	const [couponCode, setCouponCode] = useState<string>('');

	const [dateTimeSchedules, setDateTimeSchedules] = useState<I_ScheduleDateTimeState>({
		dates: [],
		times: []
	});

	const [selectedDateTime, setSelectedDateTime] = useState<I_SelectedDateTimeState>({
		date: '',
		time: ''
	});

	const [loading, setLoading] = useState<boolean>(true);
	const [hardCopyLoading, setHardCopyLoading] = useState<boolean>(false);
	const [dateTimeLoading, setDateTimeLoading] = useState<boolean>(false);

	const labTestCartAction = async (
		testId: string,
		testData: IG_LT_MostBookedTest | IG_LT_TestDetails | I_CartItem,
		patientCount: number,
		vendorId: string,
		onError: (res: any) => void
	) => {
		const isAlreadyInCart = cartData?.items?.find((item) => item?.item?.id === testId);

		const res =
			isAlreadyInCart && (!vendorId || vendorId === cartData?.vendor?.id)
				? await updateTestCartItem(testId, patientCount)
				: await addTestToCart(testId, patientCount, vendorId);

		if (res?.success) {
			setcartTotalPriceItems((prev) => ({
				...prev,
				itemCount: res.data.itemCount,
				totalAmount: res.data.totalAmount
			}));
			setCartData(res.data);
			setItemInLocalStorage(cartStorageName, res?.data);

			// Pixel Event Handler
			LabTestAddToCartEvent({
				testData,
				patientCount: patientCount
			});

			toast.success(res?.message);
			return;
		}
		onError && onError(res);
	};

	const removeLabTestCartItem = async (testId: string, action: any = null) => {
		const res = await deleteTestCartItem(testId);

		if (res?.success) {
			setcartTotalPriceItems((prev) => ({
				...prev,
				itemCount: res.data.itemCount,
				totalAmount: res.data.totalAmount
			}));
			setCartData(res?.data);
			setItemInLocalStorage(cartStorageName, res?.data);
			toast.success(res?.message);
		} else {
			toast.error(res.message);
		}
		action && action();
	};

	const handleGetLabCartData = async () => {
		setLoading(true);
		// setCartData((prev) => ({ ...prev, items: [] }));

		const res = await getTestsCart();
		if (res?.success) {
			setCartData((prev) => ({ ...prev, ...res?.data }));
			setItemInLocalStorage(cartStorageName, res?.data);

			// UPDATE LAB CART ADDRESS
			handleUpdateLabCartLocation(res?.data?.userLocation?.id);
		} else {
			setCartData({});
		}
		setLoading(false);
	};

	const handleUpdateHardCopyConveyance = async (e) => {
		setHardCopyLoading(true);
		const res = await updateHardCopyConveyance(e.target.checked);
		if (res?.success) {
			setCartData((prev) => ({ ...prev, ...res?.data }));
			setItemInLocalStorage(cartStorageName, res?.data);
		}
		setHardCopyLoading(false);
	};

	const handleGetLabVendors = async () => {
		const res = await getLabVendors({ page: 1, limit: 10 });
		if (res?.success) {
			setLabVendors(res?.data);
		}
	};

	const handleGetSampleCollectionSchedule = async () => {
		setDateTimeLoading(true);
		const res = await getSampleCollectionSchedule();
		if (res?.success) {
			setDateTimeSchedules({
				dates: !isEmptyArray(res?.data) ? [...res?.data] : [],
				times: !isEmptyArray(res?.data) ? [...res?.data[0]?.times] : []
			});

			setSelectedDateTime((prev) => ({
				...prev,
				date: !isEmptyArray(res?.data) ? res?.data[0]?.dateValue : '',
				time: !isEmptyArray(res?.data) ? res?.data[0]?.times[0]?.id : ''
			}));
		}
		setDateTimeLoading(false);
	};

	const handleUpdateLabCartLocation = async (cartUserLocationId: string | null) => {
		const userLocationId = cartData?.userLocation?.id || cartUserLocationId;
		const shouldUpdate =
			currentlocation?.ul_id && (!userLocationId || String(currentlocation.ul_id) !== String(userLocationId));

		if (shouldUpdate) {
			const res = await updateCartAddress(currentlocation.ul_id, currentlocation.l_id);
			if (res?.success) {
				setCartData((prev) => ({ ...prev, ...res.data }));
				setItemInLocalStorage(cartStorageName, res.data);
			}
		}
	};

	useEffect(() => {
		// const user = getItemFromLocalStorage(userStorageName);
		if (isLogged) {
			handleGetLabCartData();
			handleGetLabVendors();
			handleGetSampleCollectionSchedule();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogged]);

	// console.log('CartDataContextValue:', cartData);

	return (
		<LabCartContext.Provider
			value={{
				cartData,
				loading,
				setLoading,
				hardCopyLoading,
				setHardCopyLoading,
				handleGetLabCartData,
				labTestCartAction,
				setCartData,
				removeLabTestCartItem,
				handleUpdateLabCartLocation,
				additionalNote,
				setAdditionalNote,
				labVendors,
				setLabVendors,
				handleGetSampleCollectionSchedule,
				dateTimeSchedules,
				setDateTimeSchedules,
				selectedDateTime,
				setSelectedDateTime,
				handleUpdateHardCopyConveyance,
				couponCode,
				setCouponCode,
				dateTimeLoading,
				setDateTimeLoading
			}}>
			{children}
		</LabCartContext.Provider>
	);
};

const useLabCartContext = (): I_LabCartContext => {
	const cartContext = useContext(LabCartContext);
	if (!cartContext && typeof window !== 'undefined') {
		console.error('CartContext is not available. Make sure CartContextProvider is properly set up.');
		return {};
	} else {
		return cartContext;
	}
};

export default useLabCartContext;
