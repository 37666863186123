import { request } from '../arogga-api';

import querystring from 'qs';
import { IG_LT_LabVendor } from '../types/lab-vendor/type.getLabVendor';
import { I_ResponseDataArray } from '../types/type.lab-test-apis';

export const getLabVendors = (q: any): Promise<I_ResponseDataArray<IG_LT_LabVendor>> => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/public/vendors?${qs}`,
		method: 'GET'
	});
};
